body {
  margin: 0;
  font-family: "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* 

const theme = {
    fontSize: {
        h1: 96,
        h2: 60,
        h3: 48,
        h4: 34,
        h5: 24,
        h6: 20,
        h7: 18,
        subtitle1: 16,
        subtitle2: 14,
        body1: 16,
        body2: 14,
        button: 14,
        caption: 12,
        overline: 10,
    },
    colors: {
        primaryColor: '#f0f3f9',
        primaryLightColor: '#ffffff',
        primaryDarkColor: '#bdc0c6',
        secondaryColor: '#464563',
        secondaryLightColor: '#727091',
        secondaryDarkColor: '#1d1e39',
        primaryTextColor: '#464563',
        secondaryTextColor: '#ffffff',
        error: '#EB4A8F',
        primaryPlaceholder: '#B4B4BB',
        tertiaryColor: '#77C3D0'
    }
}

*/