/* COLORES */

.primaryColor {
	color: #f0f3f9 !important
}
.primaryLightColor {
	color: #ffffff !important
}
.primaryDarkColor {
	color: #bdc0c6 !important
}
.secondaryColor {
	color: #5C6DF9 !important
}
.secondaryColorB {
	background-color: #5C6DF9 !important
}
.secondaryLightColor {
	color: #727091 !important
}
.secondaryDarkColor {
	color: #1d1e39 !important
}
.primaryTextColor {
	color: #5C6DF9 !important
}
.secondaryTextColor {
	color: #ffffff !important
}
.error {
	color: #EB4A8F !important
}
.primaryPlaceholder {
	color: #B4B4BB !important
}
.tertiaryColor {
	color: #77C3D0 !important
}
.tertiaryColorB {
	background-color: #77C3D0 !important
}
.form {
  position: relative;
  padding: 0 1em 1em;
  border: 1px solid #d8d8d8;
  width: 98%;
}

.formTitulo{
  position: relative;
  padding: 1em 0 0;
  width: 98%;
  text-align: left;
}

.App {
  /* text-align: center; */

  /* display: grid;
  grid-template-columns: 250px 1fr;
  grid-template-rows: 100px 1fr;
  grid-template-areas: "header content"       "menu content";
  background: var(--bgGradient);
  min-height: 100vh; */
  width:  98%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.container {
	width: auto !important;
	max-width: none !important;
	padding: 0 !important;
}

.btn {
	border: none !important;
	font-weight: 700 !important;
}

html, body, #root, .App, .App > .row {
  height: 100% !important;
}

body {
  margin: 0;
  font-family: "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #8C8CAB;
}

/*Estilo de botones de las tablas*/
.rt-td a {
  display: inline-block;
  background: #5C6DF9;
  color: #fff;
  height: auto;
  text-decoration: none;
  border-radius: 20px;
  overflow: hidden;
}
.rt-td a:hover {
  color: #fff;
  text-decoration: none;
}
.rt-td a h6 {
  padding: 5px 10px;
  margin: 0;
  font-size: 0.8em;
}

.rt-td a h6:hover {
  background: rgba(0, 0, 0, 0.2);
}

/*Caja de contenido*/
.mainContent {
  padding-top:40px;
}

/*Boton "Crear nuevo"*/
.btn-secondary {
  background: #FFBC6C !important;
}
.btn-secondary:hover {
  background: #E99F45 !important;
}

.btn-delete{
  background:  rgb(245, 87, 81)!important;
}
.btn-delete:hover {
  background: rgb(235, 37, 37) !important;
}

.btn-cancelar{  
  background: transparent !important;
  color: #adadad !important
}

.btn-cancelar:hover{
  background: transparent !important;
  color: #919192 !important  
}

/*Formulario*/
.formTitulo {
  width: 100%;
  margin-top:20px;
}
.formTitulo h6 {
  font-size: 1.4em;
}
.form {
  width: 100%;
  padding: 20px;
  border-radius: 5px;
}


/*Barra superior*/
.navbar-expand-md {
  margin-top: 20px;
  padding: 0 !important;
  border-radius: 5px;
}
.navbar-expand-md .nav-link:hover {
  border-radius: 10px;
  background: #5C6DF9;
  color: #fff !important;
}

.textPasswordCorrecta {
  width: 100%;
  margin: 25px 0;
  font-size: 0.9em;
}